import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Layout, Seo, Cta } from '@global'
import { Hero, PostGrid } from '@pages/caseStudy/collection'
import { ImageCard } from '@UI'

const CaseStudyCollection = ({ data }) => {
  return (
    <Layout>
      <Seo
        title='All Case Studies | Stellr Web | Cody, WY'
        description='View our recent projects and find out how Stellr Web can help your small business grow.'
      />

      <Helmet encodeSpecialCharacters={false}>
        <script type='application/ld+json'>
          {`
						{
							"@context": "https://schema.org/",
							"@type": "BreadcrumbList",
							"itemListElement": [{
								"@type": "ListItem",
								"position": 1,
								"name": "Case Studies",
								"item": "https://www.stellrweb.com/case-studies"
							}]
						}
        `}
        </script>
      </Helmet>

      <Hero image={data.heroImage} />
      <PostGrid>
        {data.caseStudies.edges.map(({ node }) => {
          const post = node.data
          return (
            <ImageCard
              title={post.main_title.text}
              excerpt={post.excerpt.text}
              image={post.main_bg_image}
              link={`/case-studies/${node.uid}`}
              key={node.id}
            />
          )
        })}
      </PostGrid>
      <Cta />
    </Layout>
  )
}

export default CaseStudyCollection

export const pageQuery = graphql`
  query CaseStudyCollection($skip: Int!, $limit: Int!) {
    heroImage: file(
      sourceInstanceName: { eq: "images" }
      name: { eq: "case-studies-hero" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: BLURRED)
      }
    }
    caseStudies: allPrismicCaseStudies(
      sort: { fields: data___publish_date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          uid
          data {
            main_title {
              text
            }
            excerpt {
              text
            }
            main_bg_image {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 700
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`
