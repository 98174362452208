import React from 'react'
import { HeroBlock } from '@blocks'

// import * as styles from './hero.module.scss'

const Hero = ({ image }) => {
  return (
    <HeroBlock
      image={image}
      titleTop='Our Recent Work'
      body='View our latest projects and find out how we can update your online presence.'
      buttons={false}
      sm
    />
  )
}

export default Hero
